import React from 'react';
import Layout from "../layouts/pl";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Fade from 'react-reveal/Fade';
import { FaCheck } from 'react-icons/fa';
import PhotoGallery from '../components/Gallery';

const Dziwnowek3 = (props) => {
  const data = useStaticQuery(graphql` 
  query {
    desktopImage: file(relativePath: { eq: "images/photos/amber3/2-main.jpg" }) {
          childImageSharp {
              fluid(quality: 100){
              ...GatsbyImageSharpFluid
              }
          }   
    }
    mobileImage: file(relativePath: { eq: "images/photos/amber3/2-mobile.jpg" }) {
      childImageSharp {
          fluid(quality: 50){
          ...GatsbyImageSharpFluid
          }
      }   
    }
  }`);

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];


  return (
    <Layout location={props.location}>
      <Fade up>
        <section className="area">
          <Img fluid={sources} alt="Dziwnowek apartamenty Amber" />
          <h1 className="title">APARTMENT - DZIWNÓW</h1>
          <div className="content-container">

            <h1>Amber Apartment 3</h1>
            <p>This 61 sq meters apartment is located on the 2nd floor of a new building with an elevator. Since July 2023 there is a swimming pool with saunas at your disposal in this building. The apartment is modern and comfortably furnished to a high standard.</p>
            <p>It has a living room with a corner sofa with a sleeping function, a fireplace and a balcony, a fully equipped kitchenette, 2 separate bedrooms with wardrobes, TV and balconies. One bedroom is equipped with a kind sized bed, the other with a comfortable sofa. The bathroom is equipped with a shower. There’s a free private parking spot (MP15) in the underground garage at your disposal accessible through the elevator.</p>
            <p>The view from the balconies in the bedrooms overlooks the Dziwna River where sailboats are moored.</p>
            <p>There is also a small playground in the housing estate.</p>
            <p>The apartment is suitable for 6 people, additionally equipped with a travel cot (without bedding) and a high chair for feeding children.</p>
            <p>There is a SPA zone (swimming pool, jacuzzi, saunas) and a fitness room included in the price of your stay - no entry limits!</p>

            <h1>Equipment:</h1>
            <ul>
              <li><FaCheck />air conditioning</li>
              <li><FaCheck />a smart TV set, 55 inch - in the living room</li>
              <li><FaCheck />2 smart TV sets, 32 inch – in the bedrooms</li>
              <li><FaCheck />wi - fi </li>
              <li><FaCheck />a fire place </li>
              <li><FaCheck />a 4 burner induction hob</li>
              <li><FaCheck />a dishwasher</li>
              <li><FaCheck />an electric filter coffee maker</li>
              <li><FaCheck />a kettle</li>
              <li><FaCheck />a toaster</li>
              <li><FaCheck />an integrated fridge / freezer</li>
              <li><FaCheck />a microwave oven</li>
              <li><FaCheck />glassware, cutlery and cooking utensils</li>
              <li><FaCheck />a dining table with 6 chairs</li>
              <li><FaCheck />a coffee table</li>
              <li><FaCheck />a shower, a washbasin with a countertop, a toilet</li>
              <li><FaCheck />a washing machine and a drying rack</li>
              <li><FaCheck />a toilet paper, soap, dishwasher blocks, washing up liquid, etc.</li>
              <li><FaCheck />a hair dryer</li>
              <li><FaCheck />a few sets of towels</li>
              <li><FaCheck />a vacuum cleaner</li>
              <li><FaCheck />a sofa with a sleeping function - in a living room and a bedroom</li>
              <li><FaCheck />a king-size bed - in a master bedroom</li>
              <li><FaCheck />a set of toys for kids</li>
              <li><FaCheck />balcony furniture</li>
              <li><FaCheck />an iron and ironining board</li>
              <li><FaCheck />a windbreaker</li>
            </ul>

            <p style={{ marginTop: 20 }}>In this apartament pets are <b>NOT allowed</b>.</p>
          </div>

        </section>
      </Fade>


      <Fade up>
        <section className="photos" id="section3">
          <h1>Gallery</h1>
          <div className="photos">
            <PhotoGallery galleryType='apartament3' />
          </div>
        </section>
      </Fade>

    </Layout>
  )
}



export default Dziwnowek3

